var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_js_modal d-flex flex-column align-center" },
    [
      _c("div", { staticClass: "title_group bbox flex-shrink-0" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
        ]),
        _c("div", {
          staticClass: "close cp",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.closeHandler.apply(null, arguments)
            },
          },
        }),
      ]),
      _c("div", { staticClass: "container" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }