<script>
export default {
  props: {
    title: {
      type: String,
      default: '标题'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    sureText: {
      type: String,
      default: '确定'
    },
    operationFlexJustify: {
      type: String,
      default: 'justify-center'
    },
  },
  methods: {
    closeHandler () {
      console.log('jsM')
      this.$emit('closeHandler')
    },
  }
}
</script>

<template>
<div
  class="c_js_modal d-flex flex-column align-center"
>
  <div
    class="title_group bbox flex-shrink-0"
  >
    <div
      class="title"
    >
      {{ title }}
    </div>
    <div
      class="close cp"
      @click.stop="closeHandler"
    ></div>
  </div>
  <div
    class="container"
  >
    <slot></slot>
  </div>
  <!--<div-->
  <!--  class="operation_group flex-shrink-0 d-flex align-center bbox"-->
  <!--  :class="{-->
  <!--    [operationFlexJustify]: true,-->
  <!--  }"-->
  <!--&gt;-->
  <!--  <div-->
  <!--    class="btn btn_light cancel"-->
  <!--   @click.stop="closeHanler"-->
  <!--  >-->
  <!--    {{ cancelText }}-->
  <!--  </div>-->
  <!--  <div-->
  <!--    class="btn btn_dark sure"-->
  <!--    @click.stop="handleSure"-->
  <!--  >-->
  <!--    {{ sureText }}-->
  <!--  </div>-->
  <!--</div>-->
</div>
</template>

<style scoped lang="scss">
.c_js_modal {
  width: 100%;
  height: 100%;
  color: #333333;
  //height: 600px;
  .title_group {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    padding: 0 40px;
    height: 46px;
    background-color: #f8f8f8;
    position: relative;
    .title {
      text-align: center;
      width: 100%;
      line-height: 46px;
      position: relative;
      z-index: 2;
    }
    .close{
      @include background(12px, 12px);
      position: absolute;
      width: 20px;
      height: 20px;
      background: {
        image: url(~@/assets/images/pop_up/close.png);
      }
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 9;
    }
  }
  .container {
    width: 100%;
    height: 100%;
  }

}
</style>